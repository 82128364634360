.bgcolor {
  --bgcolor: #C2F6C5;
  background-color: var(--bgcolor);
}
.bgcolor--two {
  --bgcolor: #F8F8F8;
}
.bgcolor--three {
  --bgcolor: #D8BEF4;
}
.bgcolor--four {
  --bgcolor: #FFF3AE;
}
.bgcolor--five {
  --bgcolor: #BCE3FF;
}
.bgcolor--six {
  --bgcolor: #2E2E2E;
  --bgcolor-hov: #000000;
}
.pageBgcolor {
  background-color: var(--bgcolor);
}
.catcolor {
  --catcolor: #000000;
}
.catcolor--one {
  --catcolor: #C2F6C5;
}
.catcolor--two {
  --catcolor: #BCE3FF;
}
.catcolor--three {
  --catcolor: #FFC0C0;
}
.catcolor--four {
  --catcolor: #FFA962;
}
.catcolor--five {
  --catcolor: #D8BEF4;
}
.catcolor--six {
  --catcolor: #FFF3AE;
}
:root {
  --sitecolor: #ABABAB;
  --textcolor: #000000;
  --titlecolor: #000000;
  --accentcolor: #000000;
  --linkcolor: #000000;
  --linkcolor-hov: #ABABAB;
  --spaceValue: 8px;
}
:root {
  --deskWidth: 1780px;
  --deskSpace: 70px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1023px) {
  :root {
    --deskSpace: 40px;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 20px;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 6px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 6px;
  }
}
:root {
  --spaceUnit: 20px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 14px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 14px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  --linkcolor-hov: var(--textcolor);
  line-height: 1.45454545;
  font-family: inherit;
  cursor: pointer;
  color: var(--linkcolor);
  padding-right: 45px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.023em;
  font-weight: 600;
  position: relative;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.button:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    color: var(--linkcolor-hov);
  }
  .button:hover:after,
  .button:focus:after {
    right: 0;
    background-color: var(--linkcolor-hov);
  }
}
/* inter-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/inter-v13-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('/extras/fonts/inter-v13-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: var(--sitecolor);
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 600;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 22px;
  font-family: 'inter', sans-serif;
  line-height: 1.45454545;
}
.unit caption {
  display: none;
}
.flag {
  background: var(--sitecolor);
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.area .unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.unit .part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 8px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--textcolor);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
  font-size: clamp(1.5rem, 1.33rem + 0.73vw, 1.875rem);
  line-height: 1.5;
}
.list--bullet li:before {
  content: '·';
  position: absolute;
  left: 0;
  top: 0;
  letter-spacing: 0.05em;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 23.4375%;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: var(--sitecolor);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: var(--sitecolor);
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'inter', sans-serif;
  line-height: 1.42857143;
  color: var(--textcolor);
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--sitecolor);
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: var(--sitecolor);
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  --linkcolor-hov: var(--textcolor);
  line-height: 1.45454545;
  font-family: inherit;
  cursor: pointer;
  color: var(--linkcolor);
  padding-right: 45px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.023em;
  font-weight: 600;
  position: relative;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  line-height: 1.42857143;
}
#disp .foot input:after,
#disp .foot a:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  #disp .foot input:hover,
  #disp .foot a:hover,
  #disp .foot input:focus,
  #disp .foot a:focus {
    color: var(--linkcolor-hov);
  }
  #disp .foot input:hover:after,
  #disp .foot a:hover:after,
  #disp .foot input:focus:after,
  #disp .foot a:focus:after {
    right: 0;
    background-color: var(--linkcolor-hov);
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'inter', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-size: 17px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 60px;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--textcolor);
  background: transparent;
  min-height: 60px;
  padding: 5px 10px;
  appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 32px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 14px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'inter', sans-serif;
  font-size: 22px;
  line-height: 1.45454545;
  background: #F0F0F0;
  height: 60px;
  border: 1px solid var(--textcolor);
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop span::file-selector-button {
  --linkcolor-hov: var(--textcolor);
  line-height: 1.45454545;
  font-family: inherit;
  cursor: pointer;
  color: var(--linkcolor);
  padding-right: 45px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.023em;
  font-weight: 600;
  position: relative;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  margin-right: 16px;
}
.unit.form .ship div.chop span::file-selector-button:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .unit.form .ship div.chop span::file-selector-button:hover,
  .unit.form .ship div.chop span::file-selector-button:focus {
    color: var(--linkcolor-hov);
  }
  .unit.form .ship div.chop span::file-selector-button:hover:after,
  .unit.form .ship div.chop span::file-selector-button:focus:after {
    right: 0;
    background-color: var(--linkcolor-hov);
  }
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  --linkcolor-hov: var(--textcolor);
  line-height: 1.45454545;
  font-family: inherit;
  cursor: pointer;
  color: var(--linkcolor);
  padding-right: 45px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.023em;
  font-weight: 600;
  position: relative;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.unit.form .submit:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .unit.form .submit:hover,
  .unit.form .submit:focus {
    color: var(--linkcolor-hov);
  }
  .unit.form .submit:hover:after,
  .unit.form .submit:focus:after {
    right: 0;
    background-color: var(--linkcolor-hov);
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: var(--textcolor);
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  --linkcolor-hov: var(--textcolor);
  line-height: 1.45454545;
  font-family: inherit;
  cursor: pointer;
  color: var(--linkcolor);
  padding-right: 45px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.023em;
  font-weight: 600;
  position: relative;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.two-step-verification-container a:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .two-step-verification-container a:hover,
  .two-step-verification-container a:focus {
    color: var(--linkcolor-hov);
  }
  .two-step-verification-container a:hover:after,
  .two-step-verification-container a:focus:after {
    right: 0;
    background-color: var(--linkcolor-hov);
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 600;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 600;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.86831812vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 68px;
}
@media (max-width: 1023px) {
  #home {
    height: 46px;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 2);
}
.layout1 #head {
  padding-right: 40%;
  box-sizing: border-box;
  margin-bottom: calc(var(--spaceTotal) * 1);
}
@media (max-width: 1023px) {
  .layout1 #head {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .layout1 #head {
    margin-top: calc(var(--spaceTotal) * 3);
    margin-bottom: calc(var(--spaceTotal) * 1);
  }
}
.layout4 #head {
  margin-top: calc(var(--spacePart) * 1);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  font-family: 'inter', sans-serif;
  font-size: clamp(1.125rem, 1.011rem + 0.48vw, 1.375rem);
  line-height: 1.45454545;
  color: var(--textcolor);
  accent-color: var(--sitecolor);
  overflow: hidden;
  hyphens: none;
  --headerOffset: 100px;
  padding-top: var(--headerOffset);
}
@media (max-width: 1023px) {
  .wrapper {
    --headerOffset: 70px;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.container {
  float: left;
  width: 100%;
  position: relative;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.section--header {
  position: fixed;
  top: 0;
  z-index: 2000;
  padding: 15px 0 ;
  box-sizing: border-box;
  background-color: #ffffff;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.section--header.navi--hidden {
  transform: translateY(-100%);
}
@media (max-width: 1023px) {
  .section--header {
    padding: 12px 0;
  }
}
.container--head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
.cbdModule--furtherLink .furtherLink {
  width: 100%;
  padding-top: calc(var(--spaceTotal) * 2);
  padding-bottom: calc(var(--spaceTotal) * 2 + 65px);
  padding-left: max(var(--deskSpace), calc((100vw - var(--deskWidth)) / 2));
  padding-right: max(var(--deskSpace), calc((100vw - var(--deskWidth)) / 2));
  --linkcolor: #ffffff;
  --linkcolor-hov: #ffffff;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: var(--bgcolor);
  font-size: clamp(1.5rem, 1.188rem + 1.33vw, 2.188rem);
  line-height: 1.42857143;
}
@media (max-width: 767px) {
  .cbdModule--furtherLink .furtherLink {
    padding-bottom: calc(var(--spaceTotal) * 2 + 40px);
  }
}
.cbdModule--furtherLink .furtherLink:after {
  content: '';
  position: absolute;
  left: max(var(--deskSpace), calc((100vw - var(--deskWidth)) / 2));
  bottom: calc(var(--spaceTotal) * 2 + 1px);
  width: 55px;
  height: 40px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (max-width: 767px) {
  .cbdModule--furtherLink .furtherLink:after {
    width: 35px;
    height: 26px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .cbdModule--furtherLink .furtherLink:hover,
  .cbdModule--furtherLink .furtherLink:focus {
    color: var(--linkcolor-hov);
    background-color: var(--bgcolor-hov);
  }
  .cbdModule--furtherLink .furtherLink:hover:after,
  .cbdModule--furtherLink .furtherLink:focus:after {
    background-color: var(--linkcolor-hov);
    left: max(calc(var(--deskSpace) + 10px), calc((100vw - var(--deskWidth)) / 2 + 10px));
  }
}
.backlink {
  float: left;
  display: inline-block;
  width: 32px;
  height: 24px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-left-black.svg);
  margin-bottom: calc(var(--spaceTotal) * 1);
  cursor: pointer;
  margin-top: calc(var(--spacePart) * 2);
}
@media (hover: hover) and (pointer: fine) {
  .backlink:hover,
  .backlink:focus {
    background-color: var(--linkcolor-hov);
  }
}
.part--moodCode {
  margin-top: calc(var(--spaceTotal) * 2);
  float: left;
  width: 100%;
}
.section--footer {
  flex-shrink: 0;
}
.footarea {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 20px 20px;
}
@media (max-width: 767px) {
  .footarea {
    grid-template-columns: 1fr;
  }
}
.footunit {
  float: left;
  width: 100%;
  grid-column: span 12;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .footunit {
    grid-column: span 1;
  }
}
.footunit--vcard {
  grid-column: span 3;
}
@media (max-width: 1023px) {
  .footunit--vcard {
    grid-column: span 7;
  }
}
@media (max-width: 767px) {
  .footunit--vcard {
    grid-column: span 1;
  }
}
.footunit--nav {
  grid-column: span 2;
}
@media (max-width: 1023px) {
  .footunit--nav {
    grid-column: span 5;
  }
}
@media (max-width: 767px) {
  .footunit--nav {
    grid-column: span 1;
  }
}
@media (max-width: 767px) {
  .footunit--nav {
    order: -1;
  }
}
.ziso {
  float: left;
  width: 100%;
}
.ziso__info {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
  padding-right: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@media (max-width: 1023px) {
  .ziso__info {
    padding-right: unset;
  }
}
.ziso__logo {
  width: 300px;
}
@media (max-width: 767px) {
  .ziso__logo {
    width: 170px;
  }
}
.ziso__text {
  font-size: 17px;
  line-height: 1.41176471;
}
@media (max-width: 767px) {
  .ziso__text {
    font-size: 15px;
    line-height: 1.33333333;
  }
}
.vcard {
  float: left;
}
.vcard .contact {
  margin-top: calc(var(--spacePart) * 2);
}
.footnav div.sub1 {
  display: flex;
  flex-direction: column;
}
.getintouch {
  margin-top: calc(var(--spacePart) * 2);
  float: left;
  display: flex;
  flex-direction: column;
}
.legal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  color: #D8D8D8;
  font-size: 14px;
  font-weight: 600;
  gap: 5px 40px;
}
#legal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px 40px;
  --linkcolor: #ABABAB;
  --linkcolor-hov: #000000;
}
.copyright {
  white-space: nowrap;
}
a {
  color: var(--linkcolor);
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  a:hover,
  a:focus {
    color: var(--linkcolor-hov);
  }
}
.area--one .part.link .open {
  --linkcolor-hov: var(--textcolor);
  line-height: 1.45454545;
  font-family: inherit;
  cursor: pointer;
  color: var(--linkcolor);
  padding-right: 45px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.023em;
  font-weight: 600;
  position: relative;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.area--one .part.link .open:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .area--one .part.link .open:hover,
  .area--one .part.link .open:focus {
    color: var(--linkcolor-hov);
  }
  .area--one .part.link .open:hover:after,
  .area--one .part.link .open:focus:after {
    right: 0;
    background-color: var(--linkcolor-hov);
  }
}
h1 {
  font-size: clamp(2.5rem, 1.364rem + 4.85vw, 5rem);
  line-height: 1.125;
  font-weight: normal;
  color: var(--textcolor);
  letter-spacing: 0.005em;
  hyphens: auto;
}
.layout1 h1 {
  font-size: clamp(1.375rem, 1.148rem + 0.97vw, 1.875rem);
  line-height: 1.5;
  color: var(--textcolor);
  letter-spacing: 0.005em;
}
h2 {
  font-size: clamp(1.75rem, 1.126rem + 2.67vw, 3.125rem);
  line-height: 1.2;
  font-weight: normal;
  color: var(--textcolor);
  letter-spacing: 0.005em;
  margin-bottom: calc(var(--spacePart) * 1);
}
h3 {
  font-size: clamp(1.5rem, 1.188rem + 1.33vw, 2.188rem);
  line-height: 1.28571429;
  font-weight: normal;
  color: var(--textcolor);
  letter-spacing: 0.005em;
}
h4 {
  font-size: clamp(1.5rem, 1.188rem + 1.33vw, 2.188rem);
  line-height: 1.28571429;
  font-weight: normal;
  color: var(--catcolor);
  letter-spacing: 0.005em;
}
h5 {
  font-size: clamp(1.125rem, 1.068rem + 0.24vw, 1.25rem);
  line-height: 1.35;
  font-weight: normal;
  color: var(--textcolor);
  letter-spacing: 0.005em;
}
.loud {
  font-size: clamp(1.375rem, 1.148rem + 0.97vw, 1.875rem);
  line-height: 1.5;
  color: var(--textcolor);
  letter-spacing: 0.005em;
}
.norm {
  color: var(--textcolor);
}
.pale {
  font-size: clamp(0.938rem, 0.881rem + 0.24vw, 1.063rem);
  line-height: 1.41176471;
  color: var(--textcolor);
}
.area--one {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.area--one.area1 {
  margin-top: calc(var(--spaceTotal) * 1);
}
.layout4 .area--one.area1,
.layout3 .area--one.area1,
.layout2 .area--one.area1 {
  margin-bottom: 0;
}
.area--one .unitOne--1-2 .unit__body {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0 20px;
}
.area--one .unitOne--1-2 .unit__body div.less,
.area--one .unitOne--1-2 .unit__body div.more {
  grid-column: span 6;
}
.area--one .unitOne--1-2 .unit__body .part {
  grid-column: span 6;
}
.area--one .unitOne--1-2 .unit__body .part--slim {
  grid-column: span 3;
}
.area--one .unitOne--1-2 .unit__body .part--tiny {
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
  grid-column: span 2;
}
@media (max-width: 767px) {
  .area--one .unitOne--1-2 .unit__body .part--tiny {
    grid-column: span 3;
  }
}
@media (max-width: 1023px) {
  .area--one .unitThree {
    min-height: clamp(150px, 15vw, 250px);
  }
}
.area--one .unitThree .unit__background {
  width: clamp(150px, 20vw, 375px);
  position: absolute;
  aspect-ratio: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1023px) {
  .area--one .unitThree .unit__background {
    bottom: -20px;
    right: 0;
    width: clamp(150px, 15vw, 250px);
  }
}
.area--one .unit--fold {
  --spacePart: 10px;
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .area--one .unit--fold.unitOne--1-2 {
    margin-top: calc(var(--spaceTotal) * -1);
  }
}
.area--one .unit--fold .unit__content {
  border-bottom: 1px solid #000000;
}
.area--one .unit--fold + .unit--fold {
  margin-top: -1px;
}
.area--one .unit--fold:nth-last-child(1 of .unit--fold) {
  margin-bottom: calc(var(--spaceUnit) * 1);
}
.area--one .unit--fold div.more {
  padding-right: 60px;
  box-sizing: border-box;
  display: block !important;
  height: 0;
  overflow: hidden;
  transition: all 0.4s;
}
.area--one .unit--fold div.more .part {
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.2s;
}
.area--one .unit--fold div.more .part:last-child {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .area--one .unit--fold div.more {
    padding-right: 0;
  }
}
.area--one .unit--fold .fold-toggle {
  font-size: clamp(1.5rem, 1.188rem + 1.33vw, 2.188rem);
  line-height: 1.42857143;
  font-weight: normal;
  letter-spacing: 0.005em;
  color: var(--catcolor);
  padding-right: 60px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding-top: calc(var(--spacePart) * 1);
  padding-bottom: calc(var(--spacePart) * 1);
  text-decoration: unset!important;
}
.area--one .unit--fold .fold-toggle:after {
  content: '';
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
  width: 40px;
  height: 40px;
  background-color: var(--catcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/fold-toggle-open.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (max-width: 767px) {
  .area--one .unit--fold .fold-toggle:after {
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
  }
}
.area--one .unit--fold .fold-toggle--open:after {
  transform: rotate(45deg);
}
.area--one .unit--fold.unit--foldOpen .more {
  height: var(--js-elementHeight);
  padding-bottom: calc(var(--spacePart) * 1);
}
.area--one .unit--fold.unit--foldOpen .more .part {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s 0.1s;
}
.area--one .unit--fold .unit__foot {
  display: none;
}
.area--one .unitTwo {
  margin-top: calc(var(--spaceUnit) * 2);
  margin-bottom: calc(var(--spaceUnit) * 2);
}
.area--one .unitFour .unit__background {
  margin-top: calc(var(--spacePart) * 1);
  margin-bottom: calc(var(--spacePart) * 1);
}
.area--one .part.pict.part--styleOne .cb-image-caption {
  margin-top: 10px;
  font-size: 17px;
  line-height: 1.41176471;
  letter-spacing: 0.05em;
}
.area--one .unitFour.unitFour--variantTwo .list--bullet li {
  padding-left: 15px;
  font-size: 17px;
  line-height: 1.41176471;
}
@media (max-width: 767px) {
  .area--one .unitFour.unitFour--variantTwo .list--bullet li {
    font-size: 15px;
    line-height: 1.33333333;
  }
}
.part.pict.part--styleTwo .cb-image-caption {
  width: auto;
  padding: 3px 10px;
  border-radius: 30px;
  color: var(--textcolor);
  background-color: var(--catcolor);
  box-sizing: border-box;
  margin-top: 0px;
  font-size: 15px;
  letter-spacing: 0.05em;
  position: absolute;
  top: 10px;
  left: 10px;
}
@media (max-width: 767px) {
  .area--one .unitOne--1-1 .part.pict + .pict {
    margin-top: calc(var(--spaceUnit) * 2 + var(--spacePart));
  }
}
.area--one .part.text .open {
  text-decoration: underline;
}
.part.pict a .cb-image-container {
  overflow: hidden;
}
.part.pict a .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .part.pict a:hover .cb-image-container img,
  .part.pict a:focus .cb-image-container img {
    scale: 1.1;
  }
}
@media screen and (prefers-reduced-motion: no-preference) {
  #view #head {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.8s cubic-bezier(0.65, 0, 0.35, 1);
  }
  #view #head.is-intersecting {
    opacity: 1;
    transform: translateY(0);
  }
  #view .unit {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.8s cubic-bezier(0.65, 0, 0.35, 1);
  }
  #view .unit.is-intersecting {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 1279px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .navigation {
    display: none!important;
  }
}
.togglenavigation {
  position: relative;
  z-index: 2001;
  width: 40px;
  height: 26px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: var(--textcolor);
  border-radius: 3px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: calc(50% - 1px);
  transition-duration: 0.2s;
}
.tline--4 {
  top: unset;
  bottom: 0;
  -duration: 0s;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
  transition-duration: 0s;
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
  transition-duration: 0s;
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: var(--textcolor);
}
.mobile-navigation div.navi > .item > .menu.path {
  font-weight: 600;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 5vw;
  margin: 160px 0 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.mobile-navigation div.sub1 > .item > .menu {
  font-size: 28px;
  line-height: 1.25;
  letter-spacing: 0;
  padding-right: 40px;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 40px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
/*# sourceMappingURL=./screen-small.css.map */